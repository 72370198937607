/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function($) {

  var slugify = function slugify(str) {
    str = str || "";
    return str.replace(/[^A-Za-z0-9]+/g, '-').toLowerCase();
  };

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages

    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var width = $(window).width();

        

        //ACTIONS FOR MOBILE MENU
        if (width < 768) {

          var resize = function resize(){
            //MAKES MENU LEVEL CONTAINER WIDTH 3 TIMES WINDOW WIDTH
            $(".levels").css("width", width * 3);

            //MAKES MENU LEVEL WRAPPER SAME WIDTH AS WINDOW
            $(".level, .levels-view").css("width", width);
          }; //end function

          $(window).load(resize);
          $(window).resize(resize);

          //ADDS ARROW TO MENU ITEMS THAT HAS CHILDREN
          $(".menu-item-has-children a").addClass("has-arrow");
          $(".menu-item-has-children").append("<div class='arrow'></div><div class='clearfix'></div>");

          //MENU SLIDE NAVIGATION FOR 1ST LEVEL
          $(".menu-item-has-children .arrow").click(function(){

            var item_id = $(this).closest("li").attr("id");
            var sub_menu = $(this).closest("li").find("ul.sub-menu").html();

            $(".submenu2").empty().append(sub_menu);

            $(this).closest(".levels").addClass("minusone");
            $(".level").removeClass("current_level");
            $(".second-level").addClass("current_level");
          });

          //MENU SLIDE NAVIGATION FOR 2ND LEVEL
          $('.submenu2').delegate('.arrow','click',function() {
              var item_id = $(this).closest("li").attr("id");
              var sub_menu = $(this).closest("li").find("ul.sub-menu").html();

              $(".submenu3").empty().append(sub_menu);

              $(this).closest(".levels").addClass("minustwo");
              $(".level").removeClass("current_level");
              $(".third-level").addClass("current_level");
          });

          //MENU BACK BUTTON FOR 2ND LEVEL
          $('.second-level .back-button').click(function() {
              $(this).closest(".levels").removeClass("minusone");
              $(".level").removeClass("current_level");
              $(".first-level").addClass("current_level");
          });

          //MENU BACK BUTTON FOR 3RD LEVEL
          $('.third-level .back-button').click(function() {
              $(this).closest(".levels").removeClass("minustwo").addClass("minusone");
              $(".level").removeClass("current_level");
              $(".second-level").addClass("current_level");
          });

          //TURNS MENU BUTTON INTO CLOSE MENU BUTTON AND VICE VERSA
          $(".menu-button").click(function(){
            $(this).toggleClass("opened-menu");
          });

        } else {

          //SHOWS MASK OVER HEADER WHEN VIEWING SUBMENU
          $("#menu-primary-navigation li:not(.button)").hover(function(){
            $(".mask").css("opacity", "1");
          }, function(){
            $(".mask").css("opacity", "0");
          });

        } //END IF

        //GO TO TOP BUTTON
        $(".go-to-top").click(function(){
          if($("body").hasClass("home")) {
            $.scrollify.move("#banner");
          } else {
            $('html,body').stop().animate({scrollTop: 0}, 600);
          }
        });



        //IMG > SVG HACK
        jQuery('img.svg').each(function(){
        var $img = jQuery(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        jQuery.get(imgURL, function(data) {
            // Get the SVG tag, ignore the rest
            var $svg = jQuery(data).find('svg');

            // Add replaced image's ID to the new SVG
            if(typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if(typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass+' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
            if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
            }

            // Replace image with new SVG
            $img.replaceWith($svg);

        }, 'xml');


        //ACTIONS TO LANDING PAGE (RUBENS)
        if($("body").hasClass("page-template-template-landing-page") || $("body").hasClass("page-template-template-landing-page-new")) {
          
          $(".slide-item").parent().hide();
          $(".slide-item").closest(".panel-first-child").show();
          $(".slide-item").closest(".panel-first-child .slide-item").addClass("slide-item-active");

          var background = $(".slide-item-active").css("background-image");
          $(".bgimage").css("background", background + "no-repeat -200px bottom");

          $(".side.iw-small").closest(".panel-grid-cell").addClass("fullscreen");
          
          $(".slide-item").each(function(){

            $(this).closest(".panel-grid-cell").css("min-height", "280px");

            var bg = $(this).css("background-image");
            var slide = $(this).find(".widget-title").text().replace(/\s+/g, '');
            $(this).attr("data-slide", slide);
            $(this).attr("data-bg", bg);
          });

          $(".slide-item-active").css("background", "none");

          $(".slide-listing ul li").each(function(){
            var slide = $(this).text().replace(/\s+/g, '');
            $(this).attr("data-slide", slide);
          });

          var firstitem = $(".slide-item-active").attr("data-slide");
          $(".slide-listing li[data-slide=" + firstitem + "]").addClass("slide-listing-active");

          $(".slide-listing li").click(function(){

            var slide = $(this).attr("data-slide");
            background = $(".slide-item[data-slide=" + slide + "]").attr("data-bg");

            $(".slide-listing li").removeClass("slide-listing-active");
            $(this).addClass("slide-listing-active");

            $(".fade-mask").fadeIn(250);
            
            $(".slide-item-active").parent().stop().fadeOut(250, function(){
              setTimeout(function(){
                $(".slide-item[data-slide=" + slide + "]").parent().stop().fadeIn(250);
                $(".slide-item").removeClass(".slide-item-active");
                $(".slide-item[data-slide=" + slide + "]").addClass("slide-item-active");
                $(".bgimage").css("background", background + "no-repeat -200px bottom");
                $(".slide-item-active").css("background", "none");
                $(".fade-mask").fadeOut(250);
              }, 300);
              
            });
          });

          //BANNER ANIMATION
          var banner2 = $('.h1a');
          var bannerText2 = banner2.text().replace(/\s+/g, ' ');
          var hackText2 = bannerText2.split('');
          var possible2 = "abFBHDVuend123456789";
          var randomLetter = function randomLetter() {
            return possible2[Math.floor(Math.random() * possible2.length)];
          };
          for (var i = 0; i < hackText2.length; ++i) {
            if (hackText2[i] === ' ') {
              continue;
            }
            hackText2[i] = randomLetter();
          }

          banner2.text(hackText2.join(''));

          setTimeout(function(){
            for (var x = 1; x < 5; x++) {

              for (var j = 0; j < hackText2.length; j++) {
                (function(k, l) {
                  setTimeout(function(){
                    if (hackText2[k] === ' ') { return; }
                    var fix = (l >= 4);
                    hackText2[k] = (fix ? bannerText2.substr(k, 1) : randomLetter());
                    banner2.text(hackText2.join(''));

                  }, k*l*8);
                })(j, x);
              }

            }
          }, 300);

          //BANNER ANIMATION
          var banner = $('.h1b');
          var bannerText = banner.text().replace(/\s+/g, ' ');
          var hackText = bannerText.split('');
          var possible = "abFBHDVuend123456789";
          var randomLetter = function randomLetter() {
            return possible[Math.floor(Math.random() * possible.length)];
          };
          for (var i = 0; i < hackText.length; ++i) {
            if (hackText[i] === ' ') {
              continue;
            }
            hackText[i] = randomLetter();
          }

          banner.text(hackText.join(''));

          setTimeout(function(){
            for (var x = 1; x < 5; x++) {

              for (var j = 0; j < hackText.length; j++) {
                (function(k, l) {
                  setTimeout(function(){
                    if (hackText[k] === ' ') { return; }
                    var fix = (l >= 4);
                    hackText[k] = (fix ? bannerText.substr(k, 1) : randomLetter());
                    banner.text(hackText.join(''));

                  }, k*l*8);
                })(j, x);
              }

            }
          }, 800);


        }

        //ACTIONS TO INTERNAL PAGES
        if(!$("body").hasClass("home") && !$("body").hasClass("page-template-template-landing-page")) {

          //CREATES INTERNAL PAGE SIDE NAV FROM H2 FOUND IN PAGE CONTENT
          var sidenavitems;

          $(".content-text h2").each(function(){

            var text = $(this).text();
            var id = slugify(text);

            $(this).attr("id", id);

            if (text==="Intro") {
              $(this).addClass("h2hide");
            }

            var item = "<li data-target='" + id + "'>" +
                       "  <div class='section-name'>" + text + "</div>" +
                       "  <div class='line'></div>" +
                       "  <div class='bullet'></div>" +
                       "  <div class='clearfix'></div>" +
                       "</li>";

            sidenavitems += item;

          });

          $(sidenavitems).appendTo(".internal-sidenav ul");

          //HIDES SIDENAV IF THERE'S ONE OR NONE ITEMS
          if($(".internal-sidenav ul li").length <= 1) {
            $(".internal-sidenav").hide();
          }

          var lastbullet;

          if($(".internal-sidenav ul li:last-child .bullet").length) {
           lastbullet = $(".internal-sidenav ul li:last-child .bullet").offsetParent().position().top;
          } else {
            lastbullet = "0";
          }

          $(".internal-sidenav .indicators").css("height", lastbullet);

          //INTERNAL PAGE SIDEBAR NAVIGATION
          $(".internal-sidenav ul li").click(function(){
            var target = "#" + $(this).data("target");

            $('html,body').stop().animate({scrollTop: $(target).offset().top - 150});

            $(".internal-sidenav ul li.active").removeClass("active");
            $(this).addClass("active");
            var activetop = $(".active").position().top;
            $(".internal-sidenav .indicators .current").css("top", activetop-17);

          });

          var sidenavoffset = $(".internal-sidenav").offset().top;

          $(window).scroll(_.throttle(function(){
            var scroll = $(window).scrollTop();
            var sidenavheight = $(".internal-sidenav").height();
            var footeroffset = $(".footer-marker").offset().top;
            var footerdistance = footeroffset - scroll;
            var docheight = $(document).height();
            var windowheight = $(window).height();
            var contentoffset = $(".page-content").offset().top;
            var footerheight = $(".footer").height();

            if(footerdistance < windowheight) {
              $(".internal-sidenav").removeClass("fixed-sidenav");
              $(".internal-sidenav").css("position", "absolute");
              $(".internal-sidenav").css("top", footeroffset - (footerheight*2)+(footerheight/2));
            } else {
              $(".internal-sidenav").addClass("fixed-sidenav");
              $(".internal-sidenav").css("top", "50%");
            }

            //INTERNAL PAGE SIDEVAR NAVIGATION FIXES ON SCROLL
            if(scroll > (sidenavoffset/2)) {
              $(".internal-sidenav").addClass("fixed-sidenav");
            } else {
              $(".internal-sidenav").removeClass("fixed-sidenav");
              $(".internal-sidenav").css("top", "0");
            }



            if ( scroll < $(".page-header").height()/2) {
              $(".internal-sidenav").removeClass("fixed-sidenav");
              $(".internal-sidenav").css("position", "absolute");
              $(".internal-sidenav").css("top", "0px");
            }

            //CHANGES COLOR OF HEADER
            if($("body").hasClass("home")) {
              if (scroll > $(".page-header").height()) {
                $("header").addClass("darkheader");
              } else {
                $("header").removeClass("darkheader");
              }
            } else {
                if (scroll > $(".page-header").height()+60) {
                $("header").addClass("darkheader");
              } else {
                $("header").removeClass("darkheader");
              }
            }



         }, 100, {trailing: true}));

          //WRAPS EVERYTHING BETWEEN H2's
          $('.page-content h2').each(function(){
              var sectionid = $(this).attr("id");
              $(this).nextUntil("h2").andSelf().wrapAll('<div class="page-section" data-section="' + sectionid + '" />');
          });


          // GET CURRENT SECTION WHEN SCROLLING THROUGH INTERNAL PAGE
          var onScroll = function onScroll(event){
              var scrollPos = $(document).scrollTop();

              $(".page-section").each(function(){
                var refElement = $(this);
                var current = $(this).data("section");
                if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height()/0.5 > scrollPos) {
                  $(".side-nav li").removeClass("active");
                  $(".side-nav li[data-target=" + current + "]").addClass("active");
                  var activetop2 = $(".active").position().top;
                  $(".internal-sidenav .indicators .current").css("top", activetop2-17);
                }
              });

          }; //END FUNCTION
          $(document).on("scroll", _.throttle(onScroll, 300, {trailing:false}));
        }


        //JQUERY UI SLIDE RANGE
        var slide = $("#slider-range").length;

        if ( slide > 0 ) {

          var minrange = $(".min").data("min-range");
          var maxrange = $(".max").data("max-range");

          $("#slider-range").slider({
            range: true,
            min: minrange,
            max: maxrange,
            step: 500,
            values: [ minrange + (minrange)*4, maxrange - (maxrange/4)],
            slide: function( event, ui ) {
              $( ".amount input" ).val( "$" + ui.values[ 0 ] + " - $" + ui.values[ 1 ] );
            }
          });

          $( ".amount input" ).val( "$" + $( "#slider-range" ).slider( "values", 0 ) +
            " - $" + $( "#slider-range" ).slider( "values", 1 ) );
        }


        //HIDE EMPTY CARS ON ASSESSMENTS PAGE
        $('.orange-border').each(function(){
          if ($(this).height() < 5) {
            $(this).hide();
          }
        });

        



    });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        var width = $(window).width();
        var sidenavtop;

        //WINDOW LOAD ANIMATIONS
        $(window).load(function(){
          setTimeout(function(){
            $(".scroll").addClass("enter");
          }, 2000);
        });


        //UNRAPS PANEL GRIDS FROM UNNECESSARY
        //CREATED BY PAGE BUILDER
        $(".panel-grid").unwrap();

        //GETS PANEL GRID ID AND ADDS CLASS,
        //SECTION NAME & ID TO MAKE SCROLLIFY WORK
        $(".panel-grid").each(function(){
          var thissection = $(this).attr("id");
          $(this).addClass("section-page " + thissection);
          $(this).attr("data-section-name", thissection);
          $(this).attr("id", thissection);
        });

        //GETS BANNER DIV & BACKGROUND-IMAGE FROM PAGE BUILDER
        var bannerdiv = $("#banner");
        var bannerbg = bannerdiv.children().css("background-image");

        //SETS BG IMAGE FOR PAGE BUILDER AS TRANSPARENT
        //& ADDS PAGE BUILDER'S BG IMAGE TO ACTUAL BANNER DIV
        bannerdiv.children().css("background", "transparent");
        bannerdiv.css("background-image", bannerbg);

        //GETS IMAGE FROM INSIDE DIV .BG AND
        //MAKES IT BACKGROUND-IMAGE ON .BG'S CSS
        $(".bg").each(function(){
          var url = $(this).find("img").attr("src");
          $(this).css("background-image", "url(" +  url + ")");
          $(this).find("img").css("display", "none");
        });

        //REMOVES WORDPRESS' UNNECESSARY <p>
        //ON .BULLET FOR MOBILE TOP NAVIGATION
        $(".section-name p:first-child, .section-name br").remove();
        $(".section-name p").unwrap();

        var beforeSlide = function beforeSlide(i, panels){

          var ref = panels[i].attr("data-section-name");

          //alert(ref);

          if(ref!==undefined) {
            $(".section-content, .bg").removeClass("enter");
          }

          //HIDES SIDENAV IF CURRENT SECTION IS BANNER
          if(ref==="banner") {
            $("#security .sidenav").css("margin-left", "-250px");
            $("#peace-of-mind .sidenav").css("margin-right", "-250px");

            $(".go-to-top").removeClass("enter");
          }

          if(ref==="peace-of-mind") {
            //HIDES 1ST SIDENAV
            $("#security .sidenav").css("margin-left", "-250px");

            //ADDS ANIMATION TO 2ND SIDENAV
            $("#peace-of-mind .sidenav, .why-spohn-text").addClass("animations");

            //HIDES WHAT WE DO TEXT
            $(".what-we-do-text").css("opacity", "0");
          }

          if(ref==="telecom") {
            //HIDES 2ND SIDENAV
            $("#peace-of-mind .sidenav").css("margin-right", "-250px");

            //HIDES WHY SPOHN TEXT
            $(".why-spohn-text").css("opacity", "0");
          }

          if(ref==="flexible-solutions"){
            $(".why-spohn-text").css("position", "fixed");
            $(".why-spohn-text").css("bottom", "-30px");
          }

          if(ref==="peace-of-mind" || ref==="flexible-solutions" || ref==="friendly-nerds") {
            $("#security .sidenav").css("margin-left", "-250px");
            $(".what-we-do-text").css("opacity", "0");
          }

          if(ref==="security" || ref==="training" || ref==="telecom") {
            $("#peace-of-mind .sidenav").css("margin-right", "-250px");
          }

          var activetop;

          if (ref==="security" || ref==="training" || ref==="telecom") {
            $(".security ul.side-nav li").removeClass("active");
            $(".security ul.side-nav li[data-target=" + ref + "]").addClass("active");
            activetop = $(".security ul.side-nav .active").position().top;
            $(".security .indicators .current").css("top", activetop-109);
          }

          if (ref==="peace-of-mind" || ref==="flexible-solutions" || ref==="friendly-nerds") {
            $(".peace-of-mind ul.side-nav li").removeClass("active");
            $(".peace-of-mind ul.side-nav li[data-target=" + ref + "]").addClass("active");
            activetop = $(".peace-of-mind ul.side-nav .active").position().top;
            $(".peace-of-mind .indicators .current").css("top", activetop-119);
          }

        };

        var afterSlide = function afterSlide(i, panels){

          $(".section-content, .bg").addClass("enter");

          var ref = panels[i].data("section-name");

          if(ref==="flexible-solutions" || ref==="friendly-nerds") {
            $("#peace-of-mind .sidenav").css("margin-right", "0");
            $(".why-spohn-text").css("opacity", "1");
          }

          if(ref==="training" || ref==="telecom") {
            $("#security .sidenav").css("margin-left", "0");
            $(".what-we-do-text").css("opacity", "1");
          }

          if(ref==="security") {
            //animates sidebar in
            $("#security .sidenav").css("margin-left", "0");

            $(".go-to-top").addClass("enter");

            $(".what-we-do-text").css("opacity", "1");

            //background animations
            // $("section.security").css("background")

          }

          if(ref==="telecom") {
            //SHOWS 1ST SIDENAV
            $("#security .sidenav").css("margin-left", "0");

            //SHOWS WHAT WE DO TEXT
            $(".what-we-do-text").css("opacity", "1");

          }

          if(ref==="peace-of-mind") {
            $("#peace-of-mind .sidenav").css("margin-right", "0");

            //ADDS ANIMATION TO 2ND SIDENAV
            $("#peace-of-mind .sidenav, .why-spohn-text, header, .bg").addClass("animations");

            //SHOWS WHY SPOHN TEXT
            $(".why-spohn-text").css("opacity", "1");
          }

          if(ref==="friendly-nerds") {
            console.log("ref:"+ref);

            $(".why-spohn-text").css("position", "absolute");
            $(".why-spohn-text").css("bottom", "calc(-200% - 30px)");

            //REMOVES ANIMATION TO 2ND SIDENAV
            $("#peace-of-mind .sidenav, .why-spohn-text, header, .bg").removeClass("animations");

            $("#peace-of-mind").css("overflow", "visible");
            $("#peace-of-mind .sidenav").css("top", "250%");
            $("#peace-of-mind .sidenav").css("position", "absolute");

          }
        };

        //SCROLIFY
        if (width >= 768) {
          $.scrollify({
            section : ".section-page",
            sectionName : "section-name",
            easing: "swing",
            scrollSpeed: 800,
            offset : 0,
            scrollbars: false,
            interstitialSection: ".footer",
            before: beforeSlide,
            after: afterSlide,
            afterResize:function() {}
          });
        } //END IF



        $(window).scroll(_.throttle(function(){
          var scroll = $(window).scrollTop();

          //SET MENU COLOR BASED ON CURRENT SECTION BACKGROUND
          if (scroll < $("#security").offset().top-80) {
            $("header").removeClass("darkheader");
          }

          if (scroll > $("#security").offset().top-80) {
            $("header").addClass("darkheader");
          }

          if (scroll > $("#peace-of-mind").offset().top-80) {
            $("header").removeClass("darkheader");
          }
        }, 50, {trailing: false}));

        $(window).scroll(_.throttle(function(){

          var scroll = $(window).scrollTop();

          if (width >= 768) {
            //MAKES MENU, SIDE NAV, AND WHY SPOHN TEXT STATIC WHEN FOOTER IS VISIBLE
            if (scroll >= $("#friendly-nerds").offset().top) {

              $("header").css("position", "absolute");
              $("header").css("top", $("#friendly-nerds").offset().top);

            }

            if (scroll < $("#friendly-nerds").offset().top) {
              console.log("fixed nav");
              $("header, #peace-of-mind .sidenav").css("position", "fixed");
              $("header").css("top", "0");
              $("#peace-of-mind .sidenav").css("top", "50%");
            }
          }

        }, 500, {trailing: false}));

        //SCROLLS TO FIRST SECTION
        $(".scroll").click(function(){
          $.scrollify.next();
        });


        //SIDEBAR NAVIGATION
        $("ul.side-nav li").click(function(){
          var target = $(this).data("target");
          $.scrollify.move("#" + target);

          $("ul.side-nav li.active").removeClass("active");
          $(".peace-of-mind ul.side-nav li[data-target=" + target + "]").addClass("active");
          var activetop = $(".active").position().top;
          $(".security .indicators .current").css("top", activetop-109);
          $(".peace-of-mind .indicators .current").css("top", activetop-119);
        });

        //TOPNAV NAVIGATION
        $("ul.top-nav li").click(function(){
          var target = "#" + $(this).data("target");

          $('html,body').stop().animate({scrollTop: $(target).offset().top});

        });


        //HOME PAGE BANNER ANIMATION
        var banner = $('#banner h1 .title');
        var bannerText = banner.text().replace(/\s+/g, ' ');
        var hackText = bannerText.split('');
        var possible = "abFBHDVuend123456789";
        var randomLetter = function randomLetter() {
          return possible[Math.floor(Math.random() * possible.length)];
        };
        for (var i = 0; i < hackText.length; ++i) {
          if (hackText[i] === ' ') {
            continue;
          }
          hackText[i] = randomLetter();
        }

        banner.text(hackText.join(''));

        setTimeout(function(){
          for (var x = 1; x < 5; x++) {

            for (var j = 0; j < hackText.length; j++) {
              (function(k, l) {
                setTimeout(function(){
                  if (hackText[k] === ' ') { return; }
                  var fix = (l >= 4);
                  hackText[k] = (fix ? bannerText.substr(k, 1) : randomLetter());
                  banner.text(hackText.join(''));

                }, k*l*8);
              })(j, x);
            }

          }
        }, 300);


          //$(this).text(actualletter);




      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
